import styled from 'styled-components'
import { CancelButton, mediaQueries } from '@opinary/ui-components'

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`

export const Result = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: end;
`

export const CloseButton = styled(CancelButton)`
  margin-top: 10px;

  ${mediaQueries.sm} {
    position: absolute;
    right: 0;
    top: 0;
  }
`
