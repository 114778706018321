import styled from 'styled-components'

export const LandingPage = styled.div`
  height: 100%;
  overflow: hidden;
`

export const LandingPageIframe = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`
