import React, { useState } from 'react'
import GlobalStyle from './GlobalStyle'
import { OverlayModal, Iframe } from '@opinary/ui-components'
import { TrackingOTB } from '@opinary/widgets-tracking'
import AdvertisingLandingPage from '../AdvertisingLandingPage'
import { Container, Result, CloseButton } from './index.style'

function App() {
  const hash = window.location.hash.slice(1) //remove #
  const [showResults, setShowResults] = useState(true)

  if (!hash) return null
  const opinaryParams = JSON.parse(decodeURIComponent(atob(hash)))
  const { pollUrl, lpUrl, trackingData, overlay, experimentMetadata } =
    opinaryParams

  return (
    <Container>
      <GlobalStyle whiteColor />
      {showResults && (
        <Result as={overlay && OverlayModal} data-testid="overlay-result">
          {!overlay && <CloseButton onClick={() => setShowResults(false)} />}
          <Iframe src={pollUrl} title="otb-poll-iframe" />
        </Result>
      )}

      <TrackingOTB
        trackingData={trackingData}
        experimentMetadata={experimentMetadata}
      />

      <AdvertisingLandingPage url={lpUrl} />
    </Container>
  )
}

export default App
