import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()
const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
