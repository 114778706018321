import React from 'react'
import { LandingPage, LandingPageIframe } from './index.style'

const AdvertisingLandingPage = ({ url }) => {
  if (!url) return null
  return (
    <LandingPage>
      <LandingPageIframe
        title="vw-iframe-client"
        id="otb-iframe-client"
        src={url}
      />
    </LandingPage>
  )
}

export default AdvertisingLandingPage
